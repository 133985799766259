import React, { useMemo } from 'react';
import { Input } from '@ui/Input';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { useDialogLocalization } from '../hooks/useDialogLocalization';
import { Bot } from '../types';
import { ReactComponent as DefaultBotIcon } from '../../../modern-ui/_deprecated/Icon/icons/default-bot.svg';
import * as css from './BotSelect.css';
import Maybe from 'graphql/tsutils/Maybe';
import { Platform } from '@globals';
import { testAllowedPlatforms } from '@utils/Platform/testAllowedPlatforms';

interface BotSelectProps {
  selectedBot?: Bot;
  bots: Bot[];
  onSelectBot(botId: string): void;
  width: string | number;
  platforms?: Maybe<Platform[]>;
}

export const BotSelect: React.FC<BotSelectProps> = ({
  selectedBot,
  bots,
  onSelectBot,
  width,
  platforms,
}) => {
  const translations = useDialogLocalization();
  const items = useMemo(
    () =>
      bots.filter(
        ({ allowedPlatforms }) =>
          !platforms ||
          !allowedPlatforms ||
          testAllowedPlatforms(platforms, allowedPlatforms),
      ),
    [bots, platforms],
  );

  return (
    <SimpleCombobox<Bot>
      selectedItem={selectedBot}
      items={items}
      onChange={(bot) => {
        if (bot && bot.id !== selectedBot?.id) {
          onSelectBot(bot.id);
        }
      }}
      menuItemStyle={{ width }}
      renderInput={({ getInputProps, openMenu }) => (
        <Input
          {...getInputProps({
            name: 'flowName',
            placeholder: bots.length
              ? translations.fullBotsDropdownTitle
              : translations.emptyBotsDropdownTitle,
            className: css.botSelectInput,
            onFocus: () => {
              openMenu();
            },
          })}
          name="botId"
          renderIconEnd={() => {
            if (!selectedBot?.status?.page_info?.picture) {
              return <DefaultBotIcon />;
            }
            return (
              <img
                src={selectedBot.status.page_info.picture}
                width={24}
                height={24}
                alt="page avatar"
              />
            );
          }}
          disabled={!bots.length}
          required
        />
      )}
    />
  );
};
