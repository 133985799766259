import React from 'react';
import { Trans } from 'react-i18next';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';

import * as css from './EmptyStates.css';

export const NotFoundState: React.FC = () => {
  const { t } = useSafeTranslation();

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={css.wrapper}
    >
      <Type size="18px" weight="semibold">
        {t('modernComponents.GlobalAttributesDialog.EmptyStates.noResults')}
      </Type>
      <Spacer factor={2} />
      <Type size="15px_DEPRECATED">
        {t(
          'modernComponents.GlobalAttributesDialog.EmptyStates.noResultsAdvice',
        )}
      </Type>
    </Flex>
  );
};

export const EmptyListState: React.FC = () => {
  const { t } = useSafeTranslation();

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={css.wrapper}
    >
      <Type size="18px" weight="semibold">
        {t('modernComponents.GlobalAttributesDialog.EmptyStates.empty')}
      </Type>
      <Spacer factor={2} />
      <Type size="15px_DEPRECATED">
        <Trans
          i18nKey="modernComponents.GlobalAttributesDialog.EmptyStates.emptyAdvice"
          t={t}
        >
          {window.i18next.t(
            'EmptyStates-JSXText--577-to-create-your-first-attribute-click',
          )}
          <Type weight="semibold" size="15px_DEPRECATED">
            {window.i18next.t('EmptyStates-JSXText--217-add-new')}
          </Type>
          {window.i18next.t('EmptyStates-JSXText-1964-below')}
        </Trans>
      </Type>
    </Flex>
  );
};
