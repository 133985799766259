import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import { Anchor } from '@ui/Links';
import { sendEvent } from '@utils/Analytics';
import { Icon } from '@ui/Icon';
import { DialogHeading } from '@ui/Dialog';

import * as css from './DialogHeader.css';

const TooltipText = () => {
  const { t } = useSafeTranslation();

  useEffect(() => {
    sendEvent({
      category: 'attributes editor',
      action: 'show tooltip',
      propertyBag: {
        text: 'Here you can see all of your bot’s attributes: system, custom, and global. They are divided by flows. Learn more about types of attributes and the difference between them.',
      },
    });
  }, []);

  const manageAttributesDoc = t('common.helpDocLinks.attributes');

  return (
    <Type as="p" size="12px" color="white">
      <Trans
        i18nKey="modernComponents.GlobalAttributesDialog.DialogHeader.tooltip"
        t={t}
      >
        {window.i18next.t(
          'DialogHeader-JSXText--181-here-you-can-see-all-of-your-bots-attributes-system-custom-and-global-they-are-divided-by-flows',
        )}
        <Anchor
          intent="tooltip"
          hideArrow
          href={manageAttributesDoc}
          target="_blank"
          rel="noopener noreferrer"
          onMouseDown={() => {
            sendEvent({
              category: 'attributes editor',
              action: 'click learn more in tooltip',
              propertyBag: {
                link: manageAttributesDoc,
              },
            });
          }}
        >
          {window.i18next.t('DialogHeader-JSXText-1076-learn-more')}
        </Anchor>
        {window.i18next.t(
          'DialogHeader-JSXText--512-about-types-of-attributes-and-the-difference-between-them',
        )}
      </Trans>
    </Type>
  );
};

export const DialogHeader: React.FC = () => {
  const { t } = useSafeTranslation();

  return (
    <DialogHeading className={css.header}>
      <Flex alignItems="center">
        <span>
          {t('modernComponents.GlobalAttributesDialog.DialogHeader.title')}
        </span>
        <Tooltip2
          placement="right"
          hoverable
          boundariesElement="viewport"
          content={<TooltipText />}
          type="small"
          positionFixed
        >
          {(ref, bind) => (
            <Icon
              className={css.headerInfo}
              ref={ref}
              size="24px"
              icon="info"
              color="grey"
              {...bind}
            />
          )}
        </Tooltip2>
      </Flex>
    </DialogHeading>
  );
};
