import React from 'react';
import { Modal } from '@ui/Modal';
import {
  FlowSelectorDialog,
  FlowSelectorDialogProps,
} from './FlowSelectorDialog';

export const FlowSelectorModal: React.FC<FlowSelectorDialogProps> = (props) => {
  return (
    <Modal onDismiss={props.onClose}>
      <FlowSelectorDialog {...props} />
    </Modal>
  );
};
