import React, { useRef, useState } from 'react';
import { uniqBy } from 'ramda';
import nanoid from 'nanoid';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useCurrentBotId } from '@utils/Routing';
import { Dialog, DialogCloseButton, DialogLoader } from '@ui/Dialog';
import { UiUserAttributeEditor } from '@components/UiUserAttributeEditor';
import {
  UserAttribute,
  UserAttributeType,
} from '@components/UiUserAttributeEditor/UserAttributeTypes';
import {
  ServiceMessageType,
  showSomethingWentWrongToaster,
  toaster,
} from '@services/MessageService';
import { useGroupingFlows } from '@utils/Data/Flow/GroupingFlows/useGroupingFlows';
import {
  addCommonAttributeToGQLCaches,
  removeCommonAttributeFromGQLCaches,
} from '@utils/AttributesUtils/AttributesUtils';
import { getAttributesDiff } from '../../components/users/getAttributesDiff';
import { ErrorState } from './ErrorState/ErrorState';
import { EmptyListState, NotFoundState } from './EmptyStates/EmptyStates';
import { DialogHeader } from './DialogHeader/DialogHeader';
import { BOT_ATTRIBUTES_QUERY, UPDATE_BOT_ATTRIBUTES } from './queries';

import * as css from './GlobalAttributesDialog.css';

import {
  BotAttributesQuery,
  BotAttributesQuery_botAttributes,
  BotAttributesQueryVariables,
} from './@types/BotAttributesQuery';
import {
  UpdateBotAttributesMutation,
  UpdateBotAttributesMutationVariables,
} from './@types/UpdateBotAttributesMutation';

const convertAttribute: (
  attr: BotAttributesQuery_botAttributes,
) => UserAttribute = ({ name, value, flowsUsingIt, blocksUsingIt }) => ({
  id: nanoid(),
  name,
  type: UserAttributeType.custom,
  values: [value ?? ''],
  flowsUsingIt,
  blocksUsingIt: blocksUsingIt || undefined,
});

interface GlobalAttributeDialogProps {
  flowId?: string;
  onDismiss: () => void;
}

export const GlobalAttributeDialog: React.FC<GlobalAttributeDialogProps> = ({
  flowId,
  onDismiss,
}) => {
  const botId = useCurrentBotId() ?? '';
  const { t } = useSafeTranslation();
  const [attributes, setAttributes] = useState<UserAttribute[]>([]);
  const savedAttributes = useRef<UserAttribute[]>([]);
  const [selectedFlowId, setSelectedFlowId] = useState<string | null>(
    flowId ?? null,
  );

  const {
    groups,
    loading: flowsLoading,
    error: flowsError,
    refetch: refetchFlows,
  } = useGroupingFlows(botId);

  const {
    loading: attributesLoading,
    error: attributesError,
    refetch: refetchAttributes,
  } = useQuery<BotAttributesQuery, BotAttributesQueryVariables>(
    BOT_ATTRIBUTES_QUERY,
    {
      fetchPolicy: 'network-only',
      skip: !botId,
      variables: {
        botId,
      },
      onCompleted: ({ botAttributes }) => {
        const mappedAttributes = botAttributes.map(convertAttribute);
        setAttributes(mappedAttributes);
        savedAttributes.current = mappedAttributes;
      },
    },
  );

  const [updateAttributes] = useMutation<
    UpdateBotAttributesMutation,
    UpdateBotAttributesMutationVariables
  >(UPDATE_BOT_ATTRIBUTES);

  const flowFilter = {
    groups,
    selectedFlowId,
    onSelectFlowId: setSelectedFlowId,
  };

  const handleTryAgainOnError = () => {
    if (flowsError) refetchFlows();
    if (attributesError) refetchAttributes();
  };

  const loading = attributesLoading || flowsLoading;
  const hasErrors = !!attributesError || !!flowsError;

  if (!botId) return null;

  return (
    <Dialog className={css.dialog}>
      <DialogCloseButton onClick={onDismiss} />
      <DialogHeader />
      {loading && <DialogLoader />}
      {hasErrors && <ErrorState onTryAgain={handleTryAgainOnError} />}

      {!loading && !hasErrors && attributes && (
        <div className={css.attributesContainer}>
          <UiUserAttributeEditor
            flowFilter={flowFilter}
            fixedAddButton
            withSearch
            excludeSystemAttributes
            readOnly={false}
            undeletable
            showBulkRemoving
            attributes={attributes}
            onChange={setAttributes}
            attributesSuggestsName={[]}
            attributesSuggestsValues={[]}
            attributeValueColumnName={t(
              'modernComponents.GlobalAttributesDialog.DialogBody.defaultValue',
            )}
            attributeValuePlaceholder={t(
              'modernComponents.GlobalAttributesDialog.DialogBody.setDefaultValue',
            )}
            notFoundComponent={<NotFoundState />}
            emptyListComponent={<EmptyListState />}
            onSaveRequest={(newAttributes) => {
              const uniqNewAttributes = uniqBy(
                (attr: UserAttribute) => attr.name,
                newAttributes,
              );

              const justAddedAttributes = uniqNewAttributes.filter(
                ({ justAdded }) => justAdded,
              );

              addCommonAttributeToGQLCaches(
                botId,
                justAddedAttributes.map(({ name }) => name),
              );

              const payload = getAttributesDiff(
                uniqNewAttributes,
                savedAttributes.current,
              );

              savedAttributes.current = uniqNewAttributes;

              const updatedAttributes = payload.update
                .filter(({ name }) => name)
                .map((item) => ({ ...item, value: item.value ?? '' }));

              if (updatedAttributes.length) {
                updateAttributes({
                  variables: {
                    botId,
                    update: { update: updatedAttributes },
                  },
                }).catch(() => {
                  toaster.show({
                    type: ServiceMessageType.error,
                    payload: {
                      message: t(
                        'modernComponents.GlobalAttributesDialog.DialogBody.errorMessage',
                      ),
                    },
                  });
                });
              }
            }}
            onBulkDeleteRequest={(names) => {
              removeCommonAttributeFromGQLCaches(botId, names);
              updateAttributes({
                variables: {
                  botId,
                  update: { delete: names },
                },
              }).catch(() => {
                showSomethingWentWrongToaster();
              });
            }}
          />
        </div>
      )}
    </Dialog>
  );
};
