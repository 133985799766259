import gql from 'graphql-tag';

export const BOTS_QUERY = gql`
  query FlowSelectorBotsQuery {
    bots {
      id
      title
      allowedPlatforms
      status {
        page_info {
          id
          picture
        }
      }
    }
  }
`;

export const BOT_FLOW_GROUPS_QUERY = gql`
  query BotFlowGroupsQuery($botId: String!) {
    bot(id: $botId) {
      id
      flow_groups {
        id
        title
        flows {
          id
          title
          platform
        }
      }
    }
  }
`;
