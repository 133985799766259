import React, { useState } from 'react';
import noop from 'lodash-es/noop';
import {
  CloseButton,
  FlexDialogContent,
} from '../../modern-ui/Dialog/ActionDialogParts';
import { Dialog, DialogHeading } from '../../modern-ui/Dialog';
import { Flex } from '../../modern-ui/Flex';
import { Loader } from '../../modern-ui/Loader';
import { TabsSet } from '../../modern-ui/Tabs';
import * as css from './GraphDialog.css';

const GraphComponent = React.lazy(() => import('./Graph'));

interface GraphDialogProps {
  botId: string;
  groupId: string;
  groupTitle: string;
  onDismiss: () => void;
}

export const GraphDialog: React.FC<GraphDialogProps> = (props) => {
  const [isWholeBotGraph, setIsWholeBotGraph] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { botId, groupId, onDismiss, groupTitle } = props;

  return (
    <Dialog className={css.dialog}>
      <FlexDialogContent className={css.content}>
        <DialogHeading>
          <Flex alignItems="baseline" justifyContent="space-between">
            <div>
              <TabsSet
                activeTabIndex={activeTabIndex}
                onTabClick={(tabIndex) => {
                  setActiveTabIndex(tabIndex);
                  setIsWholeBotGraph(tabIndex !== 0);
                }}
                tabs={[
                  {
                    id: 'group',
                    title: `${window.i18next.t(
                      'GraphDialog-Template-1626-blocks-map-for',
                    )}${groupTitle}${window.i18next.t(
                      'GraphDialog-Template-1014-group',
                    )}`,
                  },
                  {
                    id: 'bot',
                    title: window.i18next.t(
                      'GraphDialog-string--150-blocks-map-for-the-bot',
                    ),
                  },
                ]}
                createNewTab={noop}
                allowCreateNewTabs={false}
                allowEditTabs={false}
              />
            </div>
            {onDismiss ? (
              <CloseButton
                aria-label="close"
                type="button"
                onClick={onDismiss}
              />
            ) : null}
          </Flex>
        </DialogHeading>
        <div className={css.container}>
          <React.Suspense
            fallback={
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                className={css.loaderContainer}
              >
                <Loader />
              </Flex>
            }
          >
            <GraphComponent
              botId={botId}
              groupId={isWholeBotGraph ? undefined : groupId}
            />
          </React.Suspense>
        </div>
      </FlexDialogContent>
    </Dialog>
  );
};
