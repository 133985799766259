import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { getFirstFlowGroupId } from '@utils/Data/Flow/GroupingFlows/utils';
import { Platform } from '@globals';
import { FlowSelectorBotsQuery } from '../@types/FlowSelectorBotsQuery';
import { BOTS_QUERY } from '../queries';
import { useCreateNewFlow } from '../hooks/useCreateNewFlow';
import { FlowSelectorDialog } from '../FlowSelectorDialog';
import { useFlowGroups } from '../hooks/useFlowGroups';
import {
  BotFlowGroupsQuery_bot_flow_groups as FlowGroup,
  BotFlowGroupsQuery_bot_flow_groups_flows as Flow,
} from '../@types/BotFlowGroupsQuery';
import { refetchAllBlocksTitlesQuery } from '@utils/Data/Blocks/refetchAllBlocksTitlesQuery';

type FlowTriggeredCallback = (
  flowId: string,
  { botId, title }: { botId: string; title: string },
) => void;

const getFilteredFlowGroups = (
  flowGroups: FlowGroup[],
  filterFn: (flow: Flow) => boolean,
) => {
  return filterFn
    ? flowGroups.reduce((acc, group) => {
        if (group.flows) {
          // eslint-disable-next-line no-param-reassign
          group.flows = group.flows.filter((flow) => filterFn(flow));
        }
        acc.push(group);
        return acc;
      }, [] as FlowGroup[])
    : flowGroups;
};
export interface FlowSelectorDialogContainerProps {
  botId?: string;
  onSelectExisting: FlowTriggeredCallback;
  onCreateNewFlow: FlowTriggeredCallback;
  flowFilter?(flow: Flow): boolean;
  onClose(): void;
  platform: Platform;
}

export const FlowSelectorDialogContainer: React.FC<FlowSelectorDialogContainerProps> =
  ({
    botId: defaultBotId,
    flowFilter,
    onSelectExisting,
    onCreateNewFlow,
    onClose,
    platform,
  }) => {
    const [botId, setBotId] = useState<string>(defaultBotId ?? '');

    const { data, loading: botsLoading } =
      useQuery<FlowSelectorBotsQuery>(BOTS_QUERY);
    const bots = data?.bots ?? [];
    const {
      bot,
      flowGroups: originFlowGroups,
      loading: botFlowGroupsLoading,
      refetch: refetchFlowGroups,
    } = useFlowGroups(botId);
    const flowGroups = flowFilter
      ? getFilteredFlowGroups(originFlowGroups, flowFilter)
      : originFlowGroups;
    const handleCreateNewFlow: FlowTriggeredCallback = async (
      flowId,
      { title },
    ) => {
      await Promise.all([refetchFlowGroups(), refetchAllBlocksTitlesQuery(botId)]);
      onCreateNewFlow(flowId, { botId, title });
    };

    const parentGroupId = getFirstFlowGroupId(bot)!;

    const { createNewFlow, isCreatingNewFlow } = useCreateNewFlow({
      platform,
      parentGroupId,
      onCreateNewFlow: handleCreateNewFlow,
    });

    const handleSelectExistingFlow = (flowId: string) => {
      const selectedFlow = flowGroups
        .flatMap((group) => group.flows ?? [])
        .find(({ id }) => id === flowId);
      onSelectExisting(flowId, {
        botId,
        title:
          selectedFlow?.title ??
          window.i18next.t('FlowSelectorDialogContainer-string-1379-unknown'),
      });
    };

    return (
      <FlowSelectorDialog
        bots={bots}
        selectedBot={bots.find(({ id }) => id === botId)}
        onSelectBot={setBotId}
        loading={botsLoading || botFlowGroupsLoading || isCreatingNewFlow}
        flowsGroups={flowGroups}
        onAcceptSelectFlow={handleSelectExistingFlow}
        onNewFlow={() => {
          createNewFlow();
        }}
        onClose={onClose}
        platform={platform}
      />
    );
  };
