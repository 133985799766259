import { useQuery } from 'react-apollo';
import {
  BotFlowGroupsQuery,
  BotFlowGroupsQueryVariables,
} from '../@types/BotFlowGroupsQuery';
import { BOT_FLOW_GROUPS_QUERY } from '../queries';

export const useFlowGroups = (botId: string) => {
  const { data, ...queryStatus } = useQuery<
    BotFlowGroupsQuery,
    BotFlowGroupsQueryVariables
  >(BOT_FLOW_GROUPS_QUERY, {
    variables: { botId },
    skip: !botId,
    notifyOnNetworkStatusChange: true,
  });
  const flowGroups = data?.bot.flow_groups ?? [];

  return { bot: data?.bot, flowGroups, ...queryStatus };
};
