import React from 'react';
import globalLoaderSrc from '../../modern-ui/_common/images/global_loader.svg';
import * as css from './GlobalLoader.css';

export const GlobalLoader: React.FC = () => (
  <div className={css.globalLoader}>
    <img
      alt="Loading..."
      src={globalLoaderSrc}
      className={css.globalLoaderCf}
    />
  </div>
);
