import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';

import * as css from './ErrorState.css';

interface ErrorStateProps {
  onTryAgain(): void;
}

export const ErrorState: React.FC<ErrorStateProps> = ({ onTryAgain }) => {
  const { t } = useSafeTranslation();

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={css.wrapper}
    >
      <Type size="18px" weight="semibold">
        {t('modernComponents.GlobalAttributesDialog.ErrorState.message')}
      </Type>
      <Spacer factor={4} />
      <Button onClick={onTryAgain} intent="secondary">
        {t('modernComponents.GlobalAttributesDialog.ErrorState.tryAgain')}
      </Button>
    </Flex>
  );
};
