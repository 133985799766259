import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useCurrentBotId } from '@utils/Routing';
import { getFirstFlowGroupId } from '@utils/Data/Flow/GroupingFlows/utils';
import { BOT_FLOW_GROUPS_QUERY } from '../queries';
import { useCreateNewFlow } from '../hooks/useCreateNewFlow';
import { FlowSelectorModal } from '../FlowSelectorModal';
import {
  BotFlowGroupsQueryVariables,
  BotFlowGroupsQuery,
} from '../@types/BotFlowGroupsQuery';
import { DialogLocalizationContextProvider } from '../LocalizationContext';
import { Platform } from '@globals';

export interface FlowSelectorModalContainerProps {
  forceLoading: boolean;
  onSelectExisting(flowId: string): void;
  onCreateNewFlow(flowId: string): void;
  onClose(): void;
  platform?: Platform;
}

export const FlowSelectorModalContainer: React.FC<FlowSelectorModalContainerProps> =
  ({
    forceLoading,
    onSelectExisting,
    onCreateNewFlow,
    onClose,
    platform = Platform.facebook,
  }) => {
    const botId = useCurrentBotId() ?? '';
    const { data, loading: groupsLoading } = useQuery<
      BotFlowGroupsQuery,
      BotFlowGroupsQueryVariables
    >(BOT_FLOW_GROUPS_QUERY, {
      variables: { botId },
      skip: !botId,
    });

    const parentGroupId = getFirstFlowGroupId(data?.bot)!;

    const { createNewFlow, isCreatingNewFlow } = useCreateNewFlow({
      parentGroupId,
      onCreateNewFlow,
      platform,
    });

    return (
      <DialogLocalizationContextProvider
        header="modernComponents.ChooseFlowBlockModal.header"
        createFlowButtonTitle="modernComponents.ChooseFlowBlockModal.createButton"
        selectFlowButtonTitle="modernComponents.ChooseFlowBlockModal.selectButton"
      >
        <FlowSelectorModal
          loading={groupsLoading || forceLoading || isCreatingNewFlow}
          flowsGroups={data?.bot.flow_groups ?? []}
          onAcceptSelectFlow={onSelectExisting}
          onNewFlow={() => {
            createNewFlow();
          }}
          onClose={onClose}
          platform={platform}
        />
      </DialogLocalizationContextProvider>
    );
  };
