import { useState } from 'react';
import { useAddFlow, useAddFlowGroup } from '@utils/Data/Flow';
import { useCurrentBotId } from '@utils/Routing';
import { Platform } from '@globals';

interface UseCreateNewFlowParams {
  platform?: Platform;
  parentGroupId?: string;
  onCreateNewFlow?(flowId: string, { title }: { title: string }): void;
}

export const useCreateNewFlow = ({
  platform = Platform.facebook,
  parentGroupId,
  onCreateNewFlow,
}: UseCreateNewFlowParams) => {
  const botId = useCurrentBotId()!;
  const [isCreatingNewFlow, setCreatingIsNewFlow] = useState(false);
  const { addFlow } = useAddFlow({
    onCompleted: ({ id, title }) => {
      onCreateNewFlow?.(id, { title });
      setCreatingIsNewFlow(false);
    },
  });
  const { addFlowGroup } = useAddFlowGroup({
    onCompleted: (group) => {
      addFlow({
        parentGroupId: group.id,
        platform,
      });
    },
  });

  const createNewFlow = (groupId?: string) => {
    const id = groupId || parentGroupId;
    setCreatingIsNewFlow(true);
    if (id) {
      addFlow({
        parentGroupId: id,
        platform,
      });
    } else {
      addFlowGroup(botId);
    }
  };

  return { createNewFlow, isCreatingNewFlow };
};
