import gql from 'graphql-tag';

export const BOT_ATTRIBUTES_QUERY = gql`
  query BotAttributesQuery($botId: String!) {
    botAttributes(id: $botId) {
      name
      value
      flowsUsingIt
      blocksUsingIt
    }
  }
`;

export const UPDATE_BOT_ATTRIBUTES = gql`
  mutation UpdateBotAttributesMutation(
    $botId: String!
    $update: UpdateBotUserInput!
  ) {
    updateDefaultAttributes(botId: $botId, update: $update)
  }
`;
