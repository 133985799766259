import { GroupingCombobox_Group } from '@components/GroupingCombobox/GroupingCombobox';
import { Platform } from '@globals';
import { FlowSelectorGroup } from './types';

export const mapFlowsGroupsToGroupingComboboxGroups = (
  flowsGroups: FlowSelectorGroup[],
  platform: Platform | Platform[] | null,
): GroupingCombobox_Group[] => {
  return flowsGroups
    .map((flowsGroup) => ({
      title: flowsGroup.title,
      items: (flowsGroup.flows ?? []).filter(
        (item) =>
          platform === null ||
          !item.platform ||
          (Array.isArray(platform) ? platform : [platform]).includes(
            item.platform,
          ),
      ),
    }))
    .filter((flowsGroup) => flowsGroup.items.length);
};

export const containerWidth = 352;
