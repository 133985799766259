import { useCurrentBotId } from '@utils/Routing';
import React, { useEffect } from 'react';
import { FlowSelectorDialog } from '../FlowSelectorDialog';
import { useCreateNewFlow, useFlowGroups } from '../hooks';
import { Platform } from '@globals';
import { getFirstFlowGroupId } from '@utils/Data/Flow/GroupingFlows/utils';

interface FlowSelectorCallbackHandlerParams {
  flowId: string;
  isNew?: boolean;
  platform?: Platform;
}
interface FlowSelectorProps {
  onDismiss(): void;
  onSelectExisting(params: FlowSelectorCallbackHandlerParams): void;
  onCreateNewFlow?(params: FlowSelectorCallbackHandlerParams): void;
  triggerSelectIfOneFlow?: boolean;
  showCreateButton?: boolean;
}

export const FlowSelectorDialogDeeplinksContainer: React.FC<FlowSelectorProps> =
  ({
    onDismiss,
    onSelectExisting,
    onCreateNewFlow,
    triggerSelectIfOneFlow,
    showCreateButton,
  }) => {
    const botId = useCurrentBotId()!;
    const { flowGroups, loading: isFlowGroupsLoading } = useFlowGroups(botId);
    const { isCreatingNewFlow, createNewFlow } = useCreateNewFlow({
      onCreateNewFlow: (flowId) => {
        onCreateNewFlow?.({ flowId, isNew: true });
      },
    });

    useEffect(() => {
      if (
        triggerSelectIfOneFlow &&
        flowGroups?.length === 1 &&
        flowGroups[0].flows?.length === 1
      ) {
        const chosenFlow = flowGroups[0].flows[0];
        onSelectExisting({
          flowId: chosenFlow.id,
          platform: chosenFlow.platform ?? Platform.facebook,
        });
      }
    }, [flowGroups, onSelectExisting, triggerSelectIfOneFlow]);

    if (
      triggerSelectIfOneFlow &&
      (isFlowGroupsLoading ||
        (flowGroups?.length ?? 0) === 0 ||
        (flowGroups?.length === 1 && flowGroups[0].flows?.length === 1))
    ) {
      return null;
    }

    const groupId = getFirstFlowGroupId({ flow_groups: flowGroups });

    return (
      <FlowSelectorDialog
        loading={isFlowGroupsLoading || isCreatingNewFlow}
        flowsGroups={flowGroups}
        onAcceptSelectFlow={(flowId, { platform }) =>
          onSelectExisting({ flowId, platform: platform ?? undefined })
        }
        onNewFlow={() => createNewFlow(groupId)}
        onClose={onDismiss}
        showCreateButton={showCreateButton}
      />
    );
  };
