import React from 'react';
import { useCurrentFlowId } from '@utils/Routing';
import { GlobalAttributeDialog } from '../GlobalAttributesDialog';

interface CurrentFlowAttributesDialogProps {
  onDismiss(): void;
}

export const CurrentFlowAttributesDialog: React.FC<CurrentFlowAttributesDialogProps> = ({
  onDismiss,
}) => {
  const flowId = useCurrentFlowId();
  return <GlobalAttributeDialog onDismiss={onDismiss} flowId={flowId} />;
};
