import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface DialogLocalizationContextTranslations {
  header: string;
  createFlowButtonTitle: string;
  selectFlowButtonTitle: string;
  flowsDropdownTitle: string;
  botsDropdownTitle: string;
  emptyFlowsDropdownTitle: string;
  fullFlowsDropdownTitle: string;
  emptyBotsDropdownTitle: string;
  fullBotsDropdownTitle: string;
}

const INITIAL_STATE: DialogLocalizationContextTranslations = {
  header: '',
  createFlowButtonTitle: '',
  selectFlowButtonTitle: '',
  flowsDropdownTitle:
    'modernComponents.ChooseFlowBlockDialog.flowSelector.title',
  emptyFlowsDropdownTitle:
    'modernComponents.ChooseFlowBlockDialog.flowSelector.empty',
  fullFlowsDropdownTitle:
    'modernComponents.ChooseFlowBlockDialog.flowSelector.full',
  botsDropdownTitle: 'modernComponents.ChooseFlowBlockDialog.botSelector.title',
  emptyBotsDropdownTitle:
    'modernComponents.ChooseFlowBlockDialog.botSelector.empty',
  fullBotsDropdownTitle:
    'modernComponents.ChooseFlowBlockDialog.botSelector.full',
};

export const DialogLocalizationContext =
  React.createContext<DialogLocalizationContextTranslations>(INITIAL_STATE);

export const DialogLocalizationContextProvider: React.FC<
  Partial<DialogLocalizationContextTranslations>
> = ({ children, ...translations }) => {
  const { t } = useSafeTranslation();
  const value = Object.keys(INITIAL_STATE).reduce((acc, key) => {
    // @ts-ignore
    acc[key] = t(key in translations ? translations[key] : INITIAL_STATE[key]);
    return acc;
  }, {} as DialogLocalizationContextTranslations);
  return (
    <DialogLocalizationContext.Provider value={value}>
      {children}
    </DialogLocalizationContext.Provider>
  );
};
