import React, { useState, useMemo } from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Loader } from '@ui/Loader';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { GroupingCombobox } from '@components/GroupingCombobox/GroupingCombobox';
import { Platform } from '@globals';
import {
  containerWidth,
  mapFlowsGroupsToGroupingComboboxGroups,
} from './helpers';
import { BotSelect } from './components/BotSelect';
import { Bot, FlowSelectorGroup, FlowSelectorItem } from './types';
import { useDialogLocalization } from './hooks/useDialogLocalization';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

export interface FlowSelectorDialogProps {
  flowsGroups: FlowSelectorGroup[];
  loading?: boolean;
  onNewFlow?(): void;
  onAcceptSelectFlow(flowId: string, flow: FlowSelectorItem): void;
  selectedBot?: Bot;
  bots?: Bot[];
  onSelectBot?(botId: string): void;
  onClose(): void;
  showCreateButton?: boolean;
  platform?: Platform | Platform[];
}

export const FlowSelectorDialog: React.FC<FlowSelectorDialogProps> = ({
  selectedBot,
  bots,
  onSelectBot,
  flowsGroups,
  loading = false,
  onNewFlow,
  onAcceptSelectFlow,
  onClose,
  showCreateButton = true,
  platform = null,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const translations = useDialogLocalization();
  const [selectedFlowId, setSelectedFlowId] = useState<string | null>(null);
  const groups = useMemo(
    () => mapFlowsGroupsToGroupingComboboxGroups(flowsGroups, platform),
    [flowsGroups, platform],
  );
  const isNoItems = groups.length === 0;
  const withBotSelector = Boolean(bots && onSelectBot);
  const width = isSmallScreenSize ? '100%' : containerWidth;
  const menuItemWidth = isSmallScreenSize ? 300 : containerWidth;

  return (
    <DefaultDialog
      dialogStyle={{ width: '432px' }}
      header={translations.header}
      onDismiss={onClose}
      mobileAdaptive={isSmallScreenSize}
    >
      <div style={{ width }}>
        {withBotSelector && (
          <>
            <Type size="15px_DEPRECATED" weight="semibold">
              {translations.botsDropdownTitle}
            </Type>
            <BotSelect
              bots={bots!}
              selectedBot={selectedBot}
              onSelectBot={onSelectBot!}
              width={width}
              platforms={
                Array.isArray(platform)
                  ? platform
                  : platform
                  ? [platform]
                  : undefined
              }
            />
            <Spacer />
          </>
        )}
        <Type size="15px_DEPRECATED" weight="semibold">
          {translations.flowsDropdownTitle}
        </Type>
        <Spacer factor={2} />
        {loading ? (
          <Loader />
        ) : (
          <GroupingCombobox
            groups={groups}
            emptyPlaceholder={
              isNoItems
                ? translations.emptyFlowsDropdownTitle
                : translations.fullFlowsDropdownTitle
            }
            disable={isNoItems}
            selectedItemId={selectedFlowId}
            onSelect={setSelectedFlowId}
            width={menuItemWidth}
          />
        )}
        <Spacer factor={10} />
        <Flex justifyContent="flex-end">
          {showCreateButton && (
            <>
              <Button
                data-testid="flow-selector-dialog__create-flow-button"
                intent="text"
                disabled={loading || (withBotSelector && !selectedBot)}
                onClick={onNewFlow}
              >
                {translations.createFlowButtonTitle}
              </Button>
              <Spacer horizontalFactor={1} />
            </>
          )}
          <Button
            data-testid="flow-selector-dialog__select-flow-button"
            disabled={!selectedFlowId || loading}
            onClick={() => {
              if (selectedFlowId) {
                const selectedFlow = flowsGroups
                  .flatMap((group) => group.flows ?? [])
                  .find((flow) => selectedFlowId === flow.id)!;
                onAcceptSelectFlow(selectedFlow.id, selectedFlow);
              }
            }}
          >
            {translations.selectFlowButtonTitle}
          </Button>
        </Flex>
      </div>
    </DefaultDialog>
  );
};
